/* styles.css */
.custom-accordion .accordion-item,
.custom-accordion .accordion-button,
.custom-accordion .accordion-button:focus,
.custom-accordion .accordion-button.collapsed  {
    outline: none;
    box-shadow: none;
    border-radius: 5px !important; /* Override Bootstrap's default styling */
    width: 80vw;
    overflow: hidden;
    background-color: black;
    color: white;
    justify-content: left !important;
    align-items: left !important;
    text-align: left !important;
  }