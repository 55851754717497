.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.minimized-header {
  margin: 0;
  padding: 0;
  min-height: 0vh;
  overflow: hidden;
}

.Navbar {
  background-color: black;
}

.body {
  background-color: black;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-outer .carousel .slide {
  background: none;
}

@media (max-width: 768px) {
  .carousel-item {
    height: auto; /* Adjust height to auto or a specific value that fits */
    min-height: 300px; /* Ensure it has a minimum height */
  }
  .carousel-item img {
    object-fit: cover; /* Ensure the image covers the area properly */
    width: 100%;
    height: 100%;
  }
  .carousel-caption {
    background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background for better readability */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center; /* Center the text */
    font-size: 12px; /* Reduce font size on small devices */
  }

  .carousel-caption h3 {
    font-size: 14px; /* Adjust heading size for small devices */
    margin: 0; /* Remove margin for tighter fitting */
  }

  .carousel-caption p {
    font-size: 12px; /* Adjust paragraph size for small devices */
  }
}



